/* Algorithm Info Container */
.algorithm-info {
  background: rgba(26, 26, 26, 0.95);
  color: #ffffff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(255, 140, 0, 0.2);
  max-width: 900px;
  margin: 50px auto;
  font-family: 'Roboto', sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Section Titles */
.algorithm-info h2,
.algorithm-info h3 {
  font-family: 'Roboto', sans-serif;
  color: #ffae42;
  text-shadow: 0 0 10px rgba(255, 174, 66, 0.7);
}

.algorithm-info h2 {
  font-size: 2.5em;
  margin-bottom: 25px;
  text-align: center;
  animation: neon-glow 1.5s infinite alternate;
}

.algorithm-info h3 {
  font-size: 1.8em;
  margin-top: 35px;
  margin-bottom: 20px;
  border-bottom: 3px solid #ffae42;
  padding-bottom: 8px;
  text-transform: uppercase;
}

/* Text */
.algorithm-info p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #dddddd;
  margin-bottom: 25px;
}

/* Lists */
.algorithm-info ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 25px;
}

.algorithm-info li {
  margin-bottom: 15px;
  font-size: 1.1em;
}

/* Links */
.algorithm-info a {
  color: #ffae42;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.algorithm-info a:hover {
  color: #ffd27f;
  text-decoration: underline;
}

/* Code Blocks */
.algorithm-info pre {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 15px;
  overflow-x: auto;
  font-size: 1em;
  margin-top: 25px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffae42;
}

/* Responsive Design */
@media (max-width: 768px) {
  .algorithm-info {
    padding: 25px;
    margin: 25px;
  }

  .algorithm-info h2 {
    font-size: 2em;
  }

  .algorithm-info h3 {
    font-size: 1.5em;
  }

  .algorithm-info p,
  .algorithm-info li {
    font-size: 1em;
  }
}

/* Neon glow animation */
@keyframes neon-glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 174, 66, 0.6), 0 0 10px rgba(255, 174, 66, 0.6), 0 0 20px rgba(255, 174, 66, 0.6);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 174, 66, 0.8), 0 0 30px rgba(255, 174, 66, 0.8), 0 0 40px rgba(255, 174, 66, 1);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 174, 66, 0.6), 0 0 10px rgba(255, 174, 66, 0.6), 0 0 20px rgba(255, 174, 66, 0.6);
  }
}
