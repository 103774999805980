/* Import Roboto font globally */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Global styles */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: #1a1a1a; /* Dark background for contrast */
  color: #ffffff;
  overflow: hidden;
}

/* Main App container */
.App {
  background: radial-gradient(circle at center, #2c1a1a, #0d0d0d);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  color: #ffffff;
}

/* Content container */
.content {
  background: rgba(26, 26, 26, 0.9);
  padding: 40px;
  border-radius: 20px;
  max-width: 800px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 4px 20px rgba(255, 140, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

/* Title styling */
.content h1 {
  font-size: 2.5em;
  color: #ffae42;
  margin-bottom: 20px;
  text-shadow: 0 0 10px rgba(255, 174, 66, 0.7);
  animation: neon-glow 1.5s infinite alternate;
}

/* Predicted price section */
.predicted-price h2 {
  font-size: 1.7em;
  color: #ffae42;
  margin-top: 20px;
  text-shadow: 0 0 10px rgba(255, 174, 66, 0.7);
}

/* Navigation bar */
.navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.navigation a {
  color: #cccccc;
  text-decoration: none;
  margin: 0 20px;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.navigation a.active,
.navigation a:hover {
  color: #ffae42;
  text-shadow: 0 0 10px rgba(255, 174, 66, 0.7);
}

/* Buttons */
button {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 30px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
  position: relative;
  overflow: hidden;
}

button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
}

button:hover::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Dropdown select */
select {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 30px;
  border: none;
  font-size: 1em;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  box-shadow: 0 0 10px rgba(255, 174, 66, 0.2);
  transition: box-shadow 0.3s ease;
  appearance: none;
  position: relative;
}

select:hover,
select:focus {
  box-shadow: 0 0 15px rgba(255, 174, 66, 0.4);
  outline: none;
}

/* Network indicator */
.network-indicator {
  font-size: 1em;
  color: #ffffff;
  background-color: #333333;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* Network warning */
.network-warning {
  color: #ff4d4d;
  text-align: center;
  margin: 20px 0;
}

.network-warning button {
  padding: 12px 25px;
  font-size: 1em;
  cursor: pointer;
  margin: 5px;
  border-radius: 30px;
  background: linear-gradient(135deg, #ff4d4d, #ff7e5f);
  color: #ffffff;
  border: none;
  box-shadow: 0 0 15px rgba(255, 77, 77, 0.5);
}

.network-warning button:hover {
  box-shadow: 0 0 25px rgba(255, 77, 77, 0.7);
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .content {
    padding: 20px;
    height: calc(100vh - 40px);
  }

  .content h1 {
    font-size: 2em;
  }

  .predicted-price h2 {
    font-size: 1.2em;
  }

  button {
    padding: 12px 20px;
    font-size: 0.9em;
  }
}

/* Neon glow animation */
@keyframes neon-glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 174, 66, 0.6), 0 0 10px rgba(255, 174, 66, 0.6), 0 0 20px rgba(255, 174, 66, 0.6);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 174, 66, 0.8), 0 0 30px rgba(255, 174, 66, 0.8), 0 0 40px rgba(255, 174, 66, 1);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 174, 66, 0.6), 0 0 10px rgba(255, 174, 66, 0.6), 0 0 20px rgba(255, 174, 66, 0.6);
  }
}
