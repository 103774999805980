/* Wallet Connect Container */
.wallet-connect-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
  
  /* Connected Account Text */
  .connected-account {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    color: #ffae42;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(255, 174, 66, 0.7);
  }
  
  /* Wallet Connect Button */
  .wallet-connect-button {
    width: 100%;
    max-width: 250px;
    padding: 15px 0;
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .wallet-connect-button::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: rgba(255, 255, 255, 0.1);
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }
  
  .wallet-connect-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
  }
  
  .wallet-connect-button:hover::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .wallet-connect-button:active {
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(255, 126, 95, 0.6);
  }
  
  .wallet-connect-button:disabled {
    background: linear-gradient(135deg, #ccc, #999);
    box-shadow: none;
    cursor: not-allowed;
    color: #666666;
  }
  
  /* Pulse Animation */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
    }
    50% {
      box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
    }
    100% {
      box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
    }
  }
  
  .wallet-connect-button {
    animation: pulse 2s infinite;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .wallet-connect-button {
      font-size: 1em;
      padding: 12px 0;
      max-width: 200px;
    }
  
    .connected-account {
      font-size: 1em;
    }
  }
  