/* src/components/PaymentForm.css */

/* Payment Button */
.make-payment-button {
  width: 100%;
  padding: 15px 0;
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: #ffffff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
  position: relative;
  overflow: hidden;
}

.make-payment-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.make-payment-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
}

.make-payment-button:hover::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.make-payment-button:active {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(255, 126, 95, 0.6);
}

.make-payment-button:disabled {
  background: linear-gradient(135deg, #ccc, #999);
  box-shadow: none;
  cursor: not-allowed;
  color: #666666;
}

/* Payment Method Styles */
.payment-method {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.custom-radio {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1em;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.custom-radio input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .radio-btn {
  position: absolute;
  left: 0;
  top: 2px;
  height: 25px;
  width: 25px;
  background-color: #ddd;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.custom-radio .radio-btn:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-radio input[type='radio']:checked ~ .radio-btn {
  background-color: #ff7e5f;
}

.custom-radio input[type='radio']:checked ~ .radio-btn:after {
  display: block;
}

.custom-radio .radio-btn:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffffff;
}

.custom-radio:hover input ~ .radio-btn {
  background-color: #feb47b;
}
