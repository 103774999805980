/* src/components/Dropdown.css */

/* Dropdown Container */
.select-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Dropdown Styles */
.custom-dropdown {
  width: auto;
  max-width: 300px;
  min-width: 150px;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  background: rgba(255, 126, 95, 0.2);
  color: #ffffff;
  appearance: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 0 15px rgba(255, 126, 95, 0.5);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.custom-dropdown:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
}

.custom-dropdown:focus {
  outline: none;
  box-shadow: 0 0 20px rgba(255, 174, 66, 0.7);
}

/* Dropdown Arrow */
.custom-dropdown::-ms-expand {
  display: none;
}

.custom-dropdown::after {
  content: '\25BC';
  position: absolute;
  right: 20px;
  top: calc(50% - 7px);
  color: #ffffff;
  font-size: 0.8em;
  pointer-events: none;
}

/* Option Styles */
.custom-dropdown option {
  color: #000000; /* Black text for options */
  background-color: #ffffff; /* White background for options */
}

/* Responsive Design */
@media (max-width: 600px) {
  .select-container {
    width: 90%;
  }

  .custom-dropdown {
    font-size: 0.9em;
    padding: 10px 18px;
    max-width: 250px;
  }
}
