/* Prediction Table */
.prediction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: rgba(26, 26, 26, 0.95);
  box-shadow: 0 4px 20px rgba(255, 140, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.prediction-table th,
.prediction-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.prediction-table th {
  background: rgba(255, 126, 95, 0.2);
  color: #ffae42;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.9em;
}

.prediction-table td {
  color: #dddddd;
  font-size: 0.9em;
}

.prediction-table tr:nth-child(even) td {
  background: rgba(255, 255, 255, 0.05);
}

.prediction-table tr:hover td {
  background: rgba(255, 126, 95, 0.1);
  color: #ffffff;
}

/* Optional: Glow effect on hover */
.prediction-table tr:hover {
  box-shadow: inset 0 0 10px rgba(255, 174, 66, 0.5);
}
