/* Social Media Container */
.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

/* Social Icon Styles */
.social-icon {
  font-size: 1.5em;
  color: #ffffff;
  background: rgba(255, 126, 95, 0.2);
  padding: 15px;
  border-radius: 50%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-icon:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px rgba(255, 126, 95, 0.7);
  color: #ffae42;
}

/* Tooltip Styles */
.social-icon {
  position: relative;
}

.social-icon::after {
  content: attr(aria-label);
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(26, 26, 26, 0.9);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease, bottom 0.3s ease;
}

.social-icon:hover::after {
  opacity: 1;
  bottom: -45px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .social-media-container {
    gap: 15px;
  }

  .social-icon {
    font-size: 1.2em;
    padding: 12px;
  }
}
